.SearchKeywordDisplay-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.625rem;
    padding: 1rem;
    line-height: 1.375rem;
    letter-spacing: -0.025rem;

    .keyword {
      font-size: .875rem;
      font-weight: 700;
      white-space: normal;
      word-break: break-word;
    }

    .postKeyword {
      font-size: .875rem;
      font-weight: 350;
    }
}
