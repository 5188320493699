.RegisterCheckoutShipping {
    padding-top: 3.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
}

.RegisterCheckoutShipping .page-title {
    margin-bottom: 2.25rem;
}

.RegisterCheckoutShipping .input {
    margin-bottom: 1.5rem;
}

.RegisterCheckoutShipping .button {
    width: 100%;
    margin-top: 0.6875rem;
}

@media (min-width: 768px) {
    .RegisterCheckoutShipping {
        padding-left: 0;
        padding-right: 0;
    }
}
