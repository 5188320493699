/*
 * Colors
 */

:root {
    --red-transparent: rgba(255, 0, 0, 0.9);
    /* gray values need to be manually synchronized with _custom.scss */
    --gray-200: #e9ecef;
    --gray-400: #c9c9c9;
    --gray-500: #b2b2b2; /* #grey490 in Zeplin */
    --gray-600: #6c757d;
    --gray-800: #3f3f3f;
    --gray-900: #212529;
}


/*
 * Buttons
 */

.btn-dark:hover {
    background-color: var(--gray-900);
    border-color: var(--gray-900);
}
.btn-dark:focus,
.btn-dark.focus {
    background-color: var(--gray-900);
    border-color: var(--gray-900);
}
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
    background-color: var(--gray-900);
    border-color: var(--gray-900);
}

.btn-dark.disabled,
.btn-dark:disabled {
    opacity: unset;
    background-color: var(--gray-500);
    border-color: var(--gray-500);
}

/*
 * Navs
 */

/* nav underline with material style slider */
/* up to 3 tabs maximum are supported */
.nav-underline {
    position: relative;
}

.nav-underline .nav-item {
    flex: 1 1 0;
    text-align: center;
    font-weight: bold;
}

.nav-underline .nav-link {
    color: var(--gray-500);
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
    color: black;
}

.nav-slider {
    --nav-slider-width: 1.25rem;
    position: absolute;
    border-bottom: 0.1875rem solid black;
    top: 1.875rem;
    width: var(--nav-slider-width);
    transition: left 0.3s ease-out;
}
.nav-slider:nth-child(3) {
    --nav-slider-count: 2;
}
.nav-slider:nth-child(4) {
    --nav-slider-count: 3;
}
.nav-link.active:nth-of-type(1) ~ .nav-slider {
    left: calc(0.5 * (100% / var(--nav-slider-count)) - var(--nav-slider-width) / 2);
}
.nav-link.active:nth-of-type(2) ~ .nav-slider {
    left: calc(1.5 * (100% / var(--nav-slider-count)) - var(--nav-slider-width) / 2);
}
.nav-link.active:nth-of-type(3) ~ .nav-slider {
    left: calc(2.5 * (100% / var(--nav-slider-count)) - var(--nav-slider-width) / 2);
}

/*
 * Modals
 */

@media (max-width: 767.98px) {
    .modal-dialog {
        max-width: 540px;
    }
}


@media (max-width: 991.98px) {
    .modal-backdrop {
        background-color: var(--white);
    }
    .modal-backdrop.show {
       opacity: 1.0;
    }
}

/*
 * Carousel
 */

.carousel-control-next,
.carousel-control-prev {
    opacity: 0.8;
}
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
    opacity: 1.0;
}

.carousel-indicators li,
.carousel-control-next-icon,
.carousel-control-prev-icon {
    filter: drop-shadow(0 0 0.0625rem var(--dark));
}
