.InvisibleProduct-container {
    width: 100%;
    min-height: calc(100vh - 8rem - 1.5rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.InvisibleProduct-img {
    width: 3rem;
    height: 3rem;
}

.InvisibleProduct-title {
    margin-top: 1.5rem;
    font-size: 1rem;
    line-height: 1.25rem;
    letter-spacing: -0.025rem;
}

.InvisibleProduct-link {
    margin-top: 0.375rem;
    font-size: 0.75rem;
    font-weight: 350;
    line-height: 1rem;
    letter-spacing: -0.025rem;
    color: #999;
}

@media (min-width: 768px) {
    .InvisibleProduct-img {
        width: 4.5rem;
        height: 4.5rem;
    }

    .InvisibleProduct-title {
        margin-top: 2.25rem;
        font-size: 1.5rem;
        line-height: 1.875rem;
        letter-spacing: -0.0375rem;
    }

    .InvisibleProduct-link {
        margin-top: 0.5625rem;
        font-size: 1.125rem;
        line-height: 1.5rem;
        letter-spacing: -0.0375rem;
    }
}
