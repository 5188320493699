.ProductPreview {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: filter 0.25s;
}
.ProductPreview:hover,
.ProductPreview:focus {
    filter: brightness(75%);
}

.ProductPreview-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
