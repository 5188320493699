.OrderItem {
  padding: 1rem 0.75rem;
}
.OrderItem:hover {
 cursor: pointer;
}
.Order-Profile-image {
  width: 3rem;
  height: 3rem;
  margin-right: 0.5rem;
  font-size: 3rem;
  line-height: 1;
}
.Order-Info {
  line-height: normal;
}
.Order-Seller {
  font-size: 0.813rem;
  font-weight: 700;
}
.Order-Date-Status {
  color: #585858;
  font-size: 0.75rem;
  font-weight: 700;
}
.Order-Date {
  color: #585858;
  font-size: 0.75rem;
  font-weight: 350;
}
.Order-status {
  font-size: 0.75rem;
  font-weight: 700;
}
.pending {
  color: #585858;
}
.shipping {
 color: #ef0000;
}
.completed {
  color: #22b500;
}
.cancelled {
  color: #198600;
}
.OrderItem-Product-image {
  width: 3.75rem;
  height: 3.75rem;
}