.Seller-nav {
    top: 5.2rem;
    padding-bottom: 0.5rem;
    background-color: var(--white);
}
@media (max-width: 575.98px) {
    .Seller-nav {
        margin-left: -15px; /* Bootstrap override: $grid-gutter-width / 2 */
        margin-right: -15px; /* Bootstrap override: $grid-gutter-width / 2 */
        padding-left: 15px; /* Bootstrap override: $grid-gutter-width / 2 */
        padding-right: 15px; /* Bootstrap override: $grid-gutter-width / 2 */
    }
}

.Seller-nav-link {
    font-size: 0.875rem;
}

.Seller-h1-tag {
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
}
