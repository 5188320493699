.SellerReferralCode-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #000;
    padding: 0.75rem 1rem;
    color: #FFF;
}

@media (max-width: 576px) {
    .SellerReferralCode-container {
        margin-left: -1rem;
        margin-right: -1rem;
    }
}

.SellerReferralCode-title {
    font-size: 0.875rem;
    font-weight: 500;
    text-decoration: underline;
}

.SellerReferralCode-code {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.875rem;
    font-weight: bold;
    gap: 0.5rem;
}

.SellerReferralCode-copy {
    font-size: 0.875rem;
    font-weight: 500;
    background-color: #FFFFFF4D;
    border-radius: 4px;
    padding: 0.125rem 0.5rem;
}
