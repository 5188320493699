.Product-container {
    padding-top: 2rem;
}

.Product-carousel {
    position: relative;
    margin-bottom: 1rem;
    overflow: hidden;
}

@media (max-width: 991px) {
    .Product-container {
        padding-top: 0;
    }
}

.Product-carousel {
    margin: 0 -1rem 1rem -1rem;  /* same as .modal-content margin */
}
@media (min-width: 576px) {
    .Product-carousel {
        margin: 0 0 1rem 0;
    }
}
@media (min-width: 992px) {
    .Product-carousel {
        margin: 0 0 1rem 0;
    }
}

.Product-1by1 {
    position: relative;
}
.Product-1by1::before {
    content: '';
    display: block;
    padding-top: 100%;
}

.Product-1by1-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.Product-image-preload {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
}

.Product-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


@media (min-width: 992px) {
    .Product-details {
        min-height: calc(100% - 5rem);
    }
}

.Product-brand {
    font-size: 1.125rem;
    font-weight: bold;
    line-height: 1.5rem;
    text-decoration: underline;
    margin-bottom: 0.5rem;
}
.Product-brand a {
    color: inherit;
}
.Product-brand:hover {
    color: #666666;
}

.Product-title {
    font-size: 1.125rem;
    font-weight: 350;
    line-height: 1.375rem;
    margin-bottom: 1rem;
    letter-spacing: -0.025rem;
}

.Product-share {
    float: right;
    margin-top: -2.3rem;
}

.Product-description {
    color: #1F1F1F;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    font-weight: 350;
    white-space: pre-wrap;
    margin-bottom: 2rem;
}

.Product-smalltitle {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bold;
}

.Product-category-date {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.Product-category {
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 350;
    color: #999;
}

.Product-category h3 {
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 350;
    color: #999;
    text-decoration: underline;
    margin-bottom: 0;
    display: inline;
}

.Product-category h3 a {
    color: inherit;
}

.Product-date {
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 350;
    color: #999;
}

.Product-safety {
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
    padding: 0.75rem;
    align-items: center;
    background: #FAFAFA;
    border-radius: 0.5rem;
}

.Product-safety a {
    color: inherit;
    text-decoration: none;
}

.Product-safety-shield {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
}

.Product-safety-text {
    color: #4D4D4D;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.125rem;
}



.Product-subtitle {
    font-size: 1.25rem;
    line-height: 1.625rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.Product-fixed-Footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    display: flex;
    flex-direction: column;
}

.Product-actions {
    display: flex;
    justify-content: space-between;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    align-items: baseline;
    background-color: rgba(255, 255, 255);

}
@media (min-width: 992px) {
    .Product-actions {
        padding: 0.75rem 1rem;
    }
}

.Product-seller--loading {
    height: 4rem;
}

.Product-price-container {
    padding-right: 0.5rem;
    display: flex;
    flex-direction: row;
    align-content: center;
}

.Product-discount-rate {
    font-size: 1.5rem;
    color: #F00;
    font-weight: 500;
    Margin-right: 0.5rem;
}

.Product-price {
   font-size: 1.5rem;
}

.Product-buy {
    flex: 1 1 0;
    font-weight: normal;
    max-width: calc(60%);
}

@media (min-width: 992px) {
    .Product-price {
        flex: 1 1 0;
    }
    .Product-buy {
        flex: 1 1 0;
        max-width: calc(41.66667% - 0.9375rem);
    }
}

.Product-buy-shield {
    width: auto;
    height: 1.25rem;
    margin-top: -0.25rem;
    margin-right: 0.75rem;
}

.Product-actions-placeholder {
    height: 6rem;
}

.Product-policy-info {
    margin-top: 2.5rem;
}

.Product-policy-info .title {
    font-size: 1rem;
    line-height: 1.25rem;
    letter-spacing: -0.025rem;
    color: #1F1F1F;
    margin-bottom: 0;
}

.Product-policy-info .content {
    font-size: 0.875rem;
    font-weight: 350;
    line-height: 1.3125rem;
    letter-spacing: -0.025rem;
}

.Product-policy-info .content .disappear {
    display: none;
}

.Product-other-products {
    margin-top: 2.5rem;
}

.Product-section-title {
    font-weight: bold;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    line-height: 1.75rem;
}
