.Like-1by1 {
    position: relative;
}
.Like-1by1::before {
    content: '';
    display: block;
    padding-top: 100%;
}

.Like-1by1-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

