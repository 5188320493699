.Mobile-banner-container {
    height: 4.5rem;
    background: var(--white);
    border-bottom: 0.021rem solid #D9D9D9;
}

.Mobile-banner-container .AppOpenButton-wrapper {
    position: absolute;
    top: 50%;
    right: 0.9375rem;
    transform: translateY(-50%);
}

.Mobile-banner {
    display: flex;
    align-items: center;
    gap: 0.625rem;
}

.Mobile-banner .Logo {
    width: 3rem;
    height: 3rem;
}

.Mobile-banner .Title {
    color: #000000;
    font-size: 0.813rem;
    font-style: normal;
    font-weight: 350;
    line-height: 1.125rem;
}

.Mobile-banner .Description {
    color: #808080;
    font-size: 0.688rem;
    font-style: normal;
    font-weight: 350;
    display: flex;
    align-content: center;
    gap: 0.25rem;
}

.Mobile-banner .Description .Text {
    padding-top: 0.125rem;
}

.Mobile-banner .Content {
    justify-content: center;
    align-content: center;
}