.ProductsListItem {
    display: flex;
    flex-direction: column;
    line-height: 1.25;
}

.ProductsListItem-1by1 {
    position: relative;
    margin-bottom: 0.625rem;
}
.ProductsListItem-1by1::before {
    content: '';
    display: block;
    padding-top: 100%;
}
@media (max-width: 575.98px) {
    .CardDeck-item:nth-of-type(odd) .ProductsListItem-1by1 {
        margin-left: -15px; /* half of Bootstrap $grid-gutter-width */
        margin-right: -0.875rem;
    }
    .CardDeck-item:nth-of-type(even) .ProductsListItem-1by1 {
        margin-left: -0.875rem;
        margin-right: -15px; /* half of Bootstrap $grid-gutter-width */
    }
}

.ProductsListItem-1by1-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.ProductsListItem-brand-size {
    display: flex;
    flex-direction: row;
}

.ProductsListItem-brand-container {
    flex : 1;
    min-width: 0;
    align-items: center;
}

.ProductsListItem-brand-link:hover {
    text-decoration: none;
    cursor: pointer;
}

.ProductsListItem-brand {
    font-size: 0.8125rem;
    line-height: 1.125rem;
    letter-spacing: -0.025rem;
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: black;
    font-weight: bold;
}
.ProductsListItem-brand--missing {
    color: var(--gray-400);
}

.ProductsListItem-size {
    flex: 0 0 1.5625rem;
    font-size: 0.8125rem;
    line-height: 1.125rem;
    padding-left: 0.625rem;
    letter-spacing:-0.025rem;
    font-weight: bold;
}

.ProductsListItem-title {
    font-size: 0.8125rem;
    font-weight: 350;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.125rem;
    letter-spacing: -0.025rem;
    color: #606060;
    margin-top: 0.25rem;
}

.ProductsListItem-price-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.125rem;
    margin-top: 0.5rem;
}

.ProductsListItem-price {
    font-size: 0.9375rem;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.25rem;
    letter-spacing: -0.025rem;
}

.ProductsListItem-discount-rate {
    font-size: 0.9375rem;
    line-height: 1.25rem;
    letter-spacing: -0.025rem;
    padding-right: .375rem;
    color: #F00;
}

.ProductsListItem-shield {
    width: auto;
    height: 1rem;
    margin-top: -0.25rem;
    margin-left: 0.5rem;
}

.ProductsListItem-new-product {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8125rem;
    color: #606060;
    background-color: #F2F2F2;
    border-radius: 0.1875rem;
    height: 1.3125rem;
    width: 2.75rem;
    margin-top: 0.25rem;
    line-height: 1.0625rem;
    letter-spacing:-0.025rem;
    font-weight: 350;
}

.ProductsListItem-like {
    display: flex;
    flex-direction: row;
    align-content: center;
    height: 1rem;
    font-size: 0.75rem;
    font-weight: 350;
    line-height: 1rem;
    color: #999;
    margin-top: 0.375rem;
    gap: 0.25rem;
}
