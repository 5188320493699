.Profile {
    margin-top: 1rem;
}

.Profile-share {
    float: right;
}

.Profile-title {
    line-height: 1;
}

.Profile-image {
    width: 6rem;
    height: 6rem;
    font-size: 6rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    line-height: 1;
}
@media (min-width: 768px){
    .Profile-image {
        width: 8rem;
        height: 8rem;
        font-size: 8rem;
        margin-right: 1.5rem;
    }
    .Profile-myBtn {
        width: 13.8rem;
        height: 2.25rem;
    }
}

@media (max-width: 768px) {
    .Profile-actions {
        max-width: unset !important;
    }
    .Profile-myBtn {
        flex-grow: 1;
    }
}

.Profile-nickname {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 2rem;
    font-weight: bold;
}

.Profile-username {
    color: var(--gray);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem;
}

.Profile-rating {
    font-size: 1.25rem;
}
.Profile-follow-count {
    font-size: 1rem;
    line-height: 1.25rem;
    letter-spacing: -0.025rem;
    font-weight: 300;
}

.Profile-actions {
    max-width: 40rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

@media (max-width: 575.98px) {
    .Profile-follower,
    .Profile-following,
    .Profile-follow,
    .Profile-myBtn {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.875rem;
    }
    .Profile-myBtn {
        width: 10.5rem;
        height: 2.25rem;
    }
}

.Profile-follower,
.Profile-following {
    pointer-events: none;
    white-space: nowrap;
}

.Profile-follow,
.Profile-myBtn {
    font-weight: bold;
}

.Profile-bio {
    white-space: pre-wrap;
}
