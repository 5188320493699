.SoldRibbon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
}

.SoldRibbon-text {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 25%;
    color: var(--white);
    text-transform: uppercase;
    transform: translate(-50%, -50%);
}
