.StagingRibbon {
    position: fixed;
    top: 0;
    left: 0;
    margin-top: 0.5rem;
    margin-left: -1rem;
    padding: 0.125rem 1rem;
    font-size: 0.5rem;
    font-weight: bold;
    background-color: var(--primary);
    color: var(--white);
    text-transform: capitalize;
    transform: rotate(-45deg);
    z-index: 9999; /* higher than as any other layer */
}
@media (min-width: 576px) {
    .StagingRibbon {
        margin-top: 1rem;
        margin-left: -2rem;
        padding: 0.125rem 2rem;
        font-size: 0.625rem;
    }
}
@media (min-width: 1200px) {
    .StagingRibbon {
        margin-top: 2rem;
        margin-left: -3rem;
        padding: 0.25rem 4rem;
        font-size: 0.75rem;
    }
}
