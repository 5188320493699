.KakaoLoginButton {
    width: 100%;
    height: 3.125rem;
    background-color: #FEE500;
    border: 0;
    border-radius: 0.25rem;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
}
