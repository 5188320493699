.Login-container {
    padding-top: 3.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
}

.Login-email-container {
    margin-top: 3rem;
}

.Login-password-container {
    margin-top: 1.5rem;
}

.Login-buttons-container {
    display: flex;
    height: 4rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.75rem;
    font-weight: 350;
}

.Login-button-container {
    margin-top: 1.5rem;
}

.Login-social-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.Login-other-page-container {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    color: #606060;
    font-size: 0.75rem;
    font-weight: 350;
    line-height: 1rem;
    text-decoration: underline;
    text-underline-color: #606060;
}

@media (min-width: 768px) {
    .Login-container {
        padding-left: 0;
        padding-right: 0;
    }
}
