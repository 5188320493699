.Review-item {
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--light);
}
.Review-item:last-of-type {
    border-bottom: none;
}

.Review-item-reviewer {
    margin: 0 0 0.25rem 0;
}

.Review-item-reviewer-image {
    display: inline-block;
    font-size: 1.5rem;
    line-height: 1;
    vertical-align: middle;
}

.Review-item-reviewer-nickname {
    margin-left: 0.5rem;
}

.Review-item-rating {
    float: left;
    margin-right: 1rem;
}

.Review-item-date {
    color: var(--gray);
}

.Review-item-text {
    margin-bottom: 0;
    white-space: pre-wrap;
    font-size: 0.875rem;
}

.Review-item-product {
    width: 4rem;
    height: 4rem;
    font-size: 0.375rem;
}
