.Modal {
    display: block;
}

@media (max-width: 575.98px) {
    .Modal-dialog {
        margin-left: 0;
        margin-right: 0;
    }
}
@media (max-width: 992.98px) {
    .Modal-dialog {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.Modal-header {
    border-bottom: none;
    border-radius: 0;
    background-color: var(--white);
}
@media (min-width: 992px) {
    .Modal-header {
        position: absolute;
        top: -3.25rem;
        right: 0;
        background-color: transparent;
    }
}

.Modal-close {
    position: absolute;
    text-shadow: none;
}
.Modal-close:focus {
    outline: none;
}
.Modal-header .Modal-close--back {
    left: 0;
}
.Modal-header .Modal-close--close {
    right: 0;
    font-size: 2rem;
    color: var(--white);
}

.Modal-back {
    width: auto;
    height: 1rem;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

.Modal-logo {
    width: auto;
    height: 1.875rem;
    margin: -0.1875rem auto;
}

.Modal-content {
    background-color: transparent;
    border: none;
}
@media (max-width: 991.98px) {
    .Modal-content {
        position: absolute;
        top: 0;
        border: none;
        border-radius: 0;
    }
}

.Modal-body {
    background-color: var(--white);
    overflow: hidden;
}

@media (min-width: 992px) {
    .Modal-body {
        border-radius: 0;
    }
}

@media (max-width: 991px) {
    .Modal-body {
        padding-top: 0;
    }
}
