.Pc-banner-container {
    height: 4.5rem;
    background: #000000;
}

.Pc-banner-container-link:hover {
    text-decoration: none;
}

.Pc-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.25rem;
    height: 4.5rem;
    background: #000000;
}

.Pc-banner .Icon {
    width: 3rem;
    height: 3rem;
}

.Pc-banner .Text {
    color: var(--white);
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    padding-left: 1rem;
    padding-right: 2.25rem;
}
