.CheckoutButton-loading-container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: black;
    opacity: 0.5;
    z-index: 1080;
    pointer-events: none;
    touch-action: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
