.Discover-nav {
    margin-left: calc(50% - 8rem);
    margin-right: calc(50% - 8rem);
}
@media (min-width: 768px) {
    .Discover-nav {
        margin-left: calc(50% - 12rem);
        margin-right: calc(50% - 12rem);
    }
}
.Discover-h1-tag {
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
}

.Discover-nav-link {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 0.875rem;
}
@media (min-width: 768px){
    .Discover-nav-link {
        padding-left: 4rem;
        padding-right: 4rem;
    }
}

.Discover-filter {
    margin: 0.5rem 0;
    text-align: justify;
}

.Discover-filter-item {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
}
.Discover-filter-item:last-of-type {
    margin-right: auto;
}
@media (min-width: 768px){
    .Discover-filter-item {
        margin-right: 0.5rem;
    }
}

.Discover-filter-toggle {
    border-radius: 0.75rem;
    border-color: var(--gray-500);
}
