.Home-banner {
    display: flex;
    height: 66vh;
    background-position: bottom center;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
}

.Home-banner-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media (max-width: 575.98px) {
    .Home-banner {
        height: 35vh;
        margin-left: -15px; /* Bootstrap override: $grid-gutter-width / 2 */
        margin-right: -15px; /* Bootstrap override: $grid-gutter-width / 2 */
    }
    .Home-banner-image {
        height: 130%;
        margin-top: -3rem;
    }
}

.Home-section-title {
    margin-bottom: 0.3125rem;
    font-size: 1.4375rem;
    line-height: 1.8125rem;
}

.Home-section-caption {
    margin-bottom: 0.75rem;
    font-size: 0.875rem;
    line-height: 1rem;
}

.Home-section-content {
    margin-bottom: 3.5rem;
}

.Home-h1-tag {
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
}
