@media (max-width: 375px) {
    html {
        font-size: 15px;
    }
}

@media (max-width: 340px) {
    html {
        font-size: 14px;
    }
}

dialog {
    position: initial;
    left: initial;
    right: initial;
    width: initial;
    height: initial;
    border: initial;
    color: initial;
    margin: initial;
    padding: initial;
    background: initial;
}
