.Navigation {

}

.Navigation-logo {
    height: 1.875rem;
    width: auto;
}

.Navigation-toggler {
    font-size: 1rem;
    margin-right: -0.25rem;
    padding: 0.375rem;
    border: none;
}
.Navigation-toggler:hover,
.Navigation-toggler:focus {
    filter: invert(0.4);
}

.Navigation-toggler-icon {
    background-image: url('assets/menu.svg') !important;
}

.Navigation-container {
    flex-grow: 0
}

.Navigation-nav {
    height: 100vh;
}

.Navigation-item:hover {
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.05);
}

@media (min-width: 768px) {
    .Navigation-nav {
        height: auto;
    }
}

@media (max-width: 767.98px) {
    .Navigation-item {
        font-size: 1.5rem;
        text-align: right;
    }
    .Navigation-item:hover {
        background: none;
    }
}


.Navigation-link {
    color: black !important;
    font-weight: 350;
    letter-spacing: -.025rem;
}

@media (min-width: 768px) {
    .Navigation-link {
        font-size: 0.875rem;
        margin-left: 0.25rem;
        margin-right: 0.25rem;
        line-height: 1.125rem;
    }
}
