.PopularBrands-link {
    color: black;
}

.PopularBrands-loading {
    display: flex;
    height: 16.625rem; /* same height as after load, to guarantee a stable layout */
    align-items: center;
    justify-content: center;
}

.PopularBrands-text {
    font-size: 1.9375rem;
    line-height: 1.25;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0;
}
