.Settings-title {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
}
.Settings-title h1 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.75rem;
    letter-spacing: -0.025rem;
}
.Settings-items {
    gap: 1rem;
}
.Settings-item {
    padding: 1rem 0;
}
.Settings-item a, .Settings-item span {
    color: black;
    font-size: 1.5rem;
    font-weight: 350;
    line-height: 1.75rem;
    letter-spacing: -0.025rem;
}
.Settings-item a:hover {
    text-decoration: none;
}
.Settings-item span:hover {
    cursor: pointer;
}

@media (max-width: 768px) {
    .Settings-title {
        justify-content: flex-start !important;
        margin-top: 2.25rem;
        margin-bottom: 1rem;
    }

    .Settings-items {
        gap: unset;
    }
    .Settings-item {
        width: 100%;
        padding: 1.25rem 0;
        border-bottom: 0.031rem solid #D9D9D9;
    }
    .Settings-item a {
        font-size: 1rem;
        font-weight: 350;
        line-height: 1.25rem;
        letter-spacing: -0.025rem;
    }
    .Settings-item span {
        font-size: 1rem;
        font-weight: 350;
        line-height: 1.25rem;
        letter-spacing: -0.025rem;
    }
}