.Brand-nav {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.Brand-image-container {
    display: flex;
    align-items: center;
}

.Brand-logo {
    width: 6rem;
    height: 6rem;
    margin-right: 1rem;
    object-fit: contain;
    border: 0.0625rem solid var(--gray-400);
    border-radius: 50%;
}

.Brand-title-container {
    flex-grow: 1;
}

.Brand-title {
    margin-bottom: 0;
    font-size: 1.25rem;
}
@media (min-width: 768px) {
    .Brand-title {
        font-size: 1.5rem;
    }
}

.Brand-subtitle {
    margin-top: 0.5rem;
    margin-bottom: 0;
    font-size: 0.75rem;
}
@media (min-width: 768px) {
    .Brand-subtitle {
        font-size: 1rem;
    }
}

.Brand-description {
    margin-top: 2rem;
    margin-bottom: 0;
    white-space: pre-wrap;
    font-weight: 350;
}

.Brand-filter {
    margin: 0.5rem 0;
    text-align: justify;
}
.Brand-filter:empty {
    margin: 0;
}

.Brand-filter-item {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
}
.Brand-filter-item:last-of-type {
    margin-right: auto;
}
@media (min-width: 768px){
    .Brand-filter-item {
        margin-right: 0.5rem;
    }
}

.Brand-filter-toggle {
    border-radius: 0.75rem;
    border-color: var(--gray-500);
}

.Brand-filter-text {
    font-size: 1rem;
    font-weight: 700;
}
