.AppInstallModal {
    min-width: 100%;
    min-height: 100%;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.AppInstallModal-Mobile-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 0.875rem;
    padding: 2rem 1rem 1rem 1rem;
    width: 279px;
    position: relative;

    .PopupClose {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;

        .PopupCloseImage {
            margin-top: 1rem;
            margin-right: 1rem;
        }
    }

    .Logo {
        width: 4rem;
        height: 4rem;
    }

    .Appstore {
        margin-top: 1rem;
        margin-bottom: 1rem;

       .Text {
           font-size: 0.875rem;
           line-height: 1.125rem;
           letter-spacing: -0.0125rem;
           text-align: center;
           color: #00000099;
       }

       .StoreRating {
           display: flex;
           flex-direction: row;
           align-items: center;
           gap: 0.5rem;
           margin-top: 0.5rem;
           font-size: 1.25rem;
           line-height: 1.625rem;
           font-weight: 700;
           letter-spacing: -0.025rem;

           .StoreRatingImages {
               padding-bottom: 0.1875rem;
           }

           .StoreRatingStar {
               width: 1rem;
               height: 1rem;
           }
       }
    }

    .Title {
        font-size: 1.5rem;
        font-weight: bold;
        line-height: 2rem;
        letter-spacing: -0.025rem;
        white-space: pre-wrap;
        text-align: center;
    }
    .AppOpenButton {
        margin-top: 1rem;
        width: 100%;
        height: 3.125rem;
        background-color: #000000;
        color: white;
        border-radius: 0.625rem;
        font-size: 1.0625rem;
        font-weight: 500;
        border : none;
    }
}

.AppInstallModal-Pc-content {
    width: 33.75rem;
    height: 33.75rem;
    display: flex;
    flex-direction: column;
    border-radius: 1.5rem;
    padding: 3rem 1.5rem 0;
    background-color: white;
    align-items: center;
    position: relative;

    .PopupClose {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;

        .PopupCloseImage {
            margin-top: 1.5rem;
            margin-right: 1.5rem;
        }
    }

    .Title {
        font-size: 1.75rem;
        font-weight: 700;
        line-height: 2.25rem;
        letter-spacing: -0.025rem;
        text-align: center;
        white-space: pre-wrap;
    }
    .QRBackground {
        position: relative;
        width: 12.75rem;
        height: 12.75rem;
        margin-top: 1.5rem;
    }
    .QRBorder {
        position: absolute;
        top: 0;
        left: 0;
        width: 12.75rem;
        height: 12.75rem;;
    }
    .QR {
        position: absolute;
        top: 0;
        left: 0;
        margin: 2.1875rem;
        width: 8.375rem;
        height: 8.375rem;
    }
    .AppstoreContainer {
        display: flex;
        gap: 1rem;
        margin-top: 2rem;
        margin-bottom: 1.5rem;

        .Logo {
            width: 2.75rem;
            height: 2.75rem;
        }

        .Appstore {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .Text {
                font-size: 0.875rem;
                line-height: 1.125rem;
                letter-spacing: -0.0125rem;
                color: #00000099;
            }
            .StoreRating {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 0.25rem;
                font-size: 1rem;
                font-weight: 700;
                line-height: 1.375rem;
                letter-spacing: -0.025rem;

                .StoreRatingImages {
                    gap: 0.25rem;
                    padding-bottom: 0.1875rem;
                }

                .StoreRatingStar {
                    width: 1rem;
                    height: 1rem;
                }
            }
        }
    }
    .Footer {
        width: 100%;
        border-top-color: #0000001A;
        border-top-width: 0.0625rem;
        border-top-style: solid;
        padding-top: 1.5rem;
        display: flex;
        gap: 1rem;
        justify-content: center;
    }

    .DownLoadLink:hover,
    .DownLoadLink:active {
        opacity: 0.85;
    }
}
