.form-checkbox {
  width: 1.5rem;
  height: 1.5rem;
}

.form-checkbox > input {
    display: none;
}

.form-checkbox > label {
    display: block;
    width: 99.9%;
    height: 99.9%;
    background-image: url('unchecked.svg');
    background-repeat: no-repeat;
    background-size: cover;
}

.form-checkbox > input:checked + label {
    background-image: url('checked.svg');
}
