.Footer-border {
    width: 100%;
    height: 0.03125rem;
    background-color: #E6E6E6;
    margin-bottom: 3rem;
}

.Footer-legal {
    margin-top: 2rem;
    font-size: 0.75rem;
    color: #999;
    font-weight: 350;
    text-align: center;
}

.Footer-legal-in-link {
    color: #999;
    text-decoration: underline;
}

.Footer-menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    column-gap: 1.875rem;
    row-gap: 0.9375rem;
}

.Footer-link {
    color: #000;
    font-size: 0.875rem;
    font-weight: 350;
    line-height: 1.125rem;
    letter-spacing: -0.0125rem;
}

.Footer-legal-content {
    margin-bottom: 1rem;
}

.Footer-escrow-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    margin-top: 1.875rem;
    margin-bottom: 6.5rem;
}

.Footer-escrow-img {
    width: 51.75px;
    height: 60.75px;
}
