.button {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border: 0;
    border-radius: 0.313rem;
    color: #fff;
}

.button.default {
    width: 6.438rem;
    height: 2.75rem;
    background-color: #000000;
}

.button.default:disabled {
    background-color: #D9D9D9;
}

.button.full {
    width: 100%;
    height: 3.125rem;
    font-size: 1rem;
    border-radius: 0.313rem;
}
