.CheckoutPaymentMethod {
    padding: 1.5rem 0;
}

.CheckoutPaymentMethod-content {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.CheckoutPaymentMethod-select {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.CheckoutPaymentMethod-select img {
    width: 1.5rem;
    height: 1.5rem;
}

.CheckoutPaymentMethod-pay {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
    margin-top: 0.5625rem;
    margin-left: 2rem;
}
