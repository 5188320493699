.TestEnvironmentBanner {
  width: 100%;
  background-color: #FFECEB;
  padding: 0.75rem;
  border-radius: 0.375rem;
  cursor: pointer;
}

.TestEnvironmentBanner-content {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #FF4333;
  font-size: 0.813rem;
  padding-right: 1.5rem;
}

.TestEnvironmentBanner-warning {
  font-size: 1rem;
  flex-shrink: 0;
  color: #FF4333;
}

.TestEnvironmentBanner-message {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.TestEnvironmentBanner-arrow {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.125rem;
  font-weight: bold;
}