.SignUpForm-container {
    padding-top: 3.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.SignUpForm-email-container {
    margin-top: 3rem;
}

.SignUpForm-password-container {
    margin-top: 1.5rem;
}
.SignUpForm-password-spacer {
    height: 1.5rem;
}
.SignUpForm-passwordConfirm-error-message {
    margin-top: 0.125rem;
    font-size: 0.75rem;
    font-weight: 350;
    line-height: 1rem;
    letter-spacing: -0.025rem;
}

.SignUpForm-input-button-container {
    display: flex;
    align-items: flex-end;
    gap: 0.5rem;
    margin-top: 1.5rem;
}

.SignUpForm-input-button-container .input {
    flex: 1;
}

.SignUpForm-button-error {
    margin-bottom: 1.125rem;
}

.SignUpForm-agree-form-container {
    margin-top: 1.5rem;
}

.SignUpForm-all-agree-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2.75rem;
    border-bottom: 0.063rem solid #D9D9D9;
}

.SignUpForm-all-agree-text {
    flex: 1;
    padding-right: 0.5rem;
}

.SignUpForm-agree-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2.75rem;
}

.SignUpForm-agree-container a {
    color: inherit;
    text-decoration: inherit;
}

.SignUpForm-agree-text {
    flex: 1;
    padding-right: 0.5rem;
    font-size: 0.875rem;
    font-weight: 350;
}

.SignUpForm-agree-text a {
    text-decoration: underline !important;
}

.SignUpForm-select-text {
    color: #000;
}

.SignUpForm-unselect-text {
    color: #999;
}

.SignUpForm-register-button-container {
    margin-top: 1.5rem;
}

@media (min-width: 768px) {
    .SignUpForm-container {
        padding-left: 0;
        padding-right: 0;
    }
}
