.CheckoutProductDetails {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    border-radius: 0.75rem;
    background: #FFF;
    border: 0.03125rem solid #D9D9D9;
}

.CheckoutProductDetails-image {
    width: 5rem;
    height: 5rem;
    margin-right: 0.875rem;
    pointer-events: none;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border-radius: 0.25rem;
}

.CheckoutProductDetails-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.CheckoutProductDetails-brand {
    font-size: 0.75rem;
    margin-bottom: 0.25rem;
    font-weight: bold;
    line-height: 1rem;

}
.CheckoutProductDetails-brand--missing {
    color: var(--gray-400);
}

.CheckoutProductDetails-productTitle {
    font-size: 0.875rem;
    font-weight: 350;
    text-overflow: ellipsis;
    line-height: 1.125rem;
    margin-top: 0.25rem;
}

.CheckoutProductDetails-condition {
    font-size: 0.75rem;
    font-weight: 350;
    line-height: 1rem;
    color: #909090;
    margin-top: 0.5rem;
}
