.form-input-container {
    display: flex;
    flex-direction: column;
    text-align: start;
}

.form-input-container label {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    letter-spacing: -0.019rem;
}

.form-input-container .input-container {
    height: 2.75rem;
    border-radius: 0.25rem !important;
    padding: 0 0.813rem;
    background-color: #FFFFFF;
    border: 0.0625rem solid #D9D9D9;
    margin-top: 0.25rem;
    display: flex;
    align-items: center;
}

.form-input-container .error {
    border: 0.0625rem solid #F00;
}

.form-input-container .focused {
    border: 0.0625rem solid rgba(24,119,242,0.75);
}

.form-input-container .input-container input {
    width: 100%;
    height: 1.125rem;
    font-size: 0.875rem;
    font-weight: 350;
    line-height: 1.25rem;
    letter-spacing: -0.025rem;
    color: #000;
    border: none;
    outline: none;
    padding: 0;
}

.form-input-container .error-message {
    margin-top: 0.125rem;
    color: #F00;
    font-size: 0.75rem;
    font-weight: 350;
    line-height: 1rem;
    letter-spacing: -0.025rem;
}
