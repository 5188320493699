.Checkout-container {
    padding-top: 3.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.Checkout-title {
    margin-top: 2.25rem;
    margin-bottom: 1rem;
    font-size: 1.25rem;
    letter-spacing: -0.03125rem;
}

.Checkout-divider {
    width: 100%;
    height: 0.01875rem;
    background: #F4F4F4;
}

.Checkout-agree-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.Checkout-agree-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 350;
}

.Checkout-agree-text {
    font-size: 0.875rem;
    font-weight: 350;
}

.Checkout-agree-text a {
    text-decoration: underline !important;
}

.Checkout-select-text {
    color: #000;
}

.Checkout-unselect-text {
    color: #999;
}

.Checkout-soldout {
    margin-top: 1rem;
    font-size: 0.875rem;
    font-weight: 350;
}

@media (min-width: 768px) {
    .Checkout-container {
        padding-left: 0;
        padding-right: 0;
    }
}
