.Purchased-title {
  padding-top: 3.563rem;
  padding-bottom: 1rem;
  font-size: 1.5rem;
  line-height: 1.75rem;
  font-weight: 700;
  letter-spacing: -0.045rem;
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .Purchased-title {
    padding: 0.813rem 0;
    text-align: center;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.125rem;
    letter-spacing: -0.025rem;
  }
}