.App-open-button-pc {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1.125rem;
    color: var(--white);
    background: #007AFF;
    width: 6.125rem;
    height: 2.25rem;
    font-size: 0.875rem;
}

.App-open-button-pc:hover {
    text-decoration: none;
    color: white;
}

.App-open-button-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.875rem;
    color: var(--white);
    background: #007AFF;
    width: 3.688rem;
    height: 1.75rem;
    font-size: 0.75rem;
}

.App-open-button-mobile:hover {
    text-decoration: none;
    color: white;
}