.mobile-max-width-wrapper {
    margin: 0 auto;
    max-width: 767px;
    width: 100%;
}

.mobile-max-width-wrapper .page-title {
    text-align: start;
}

.mobile-max-width-wrapper h1 {
    font-size: 1.5rem;
}

@media (min-width: 768px) {
    .mobile-max-width-wrapper .page-title {
        text-align: center;
    }
    .mobile-max-width-wrapper.max-width-400 {
        max-width: 400px;
    }
    .mobile-max-width-wrapper.max-width-800 {
        max-width: 800px;
    }
}

