.CheckoutInvoice {
    margin-top: 0.75rem;
    margin-bottom: 1.5rem;
}

.CheckoutInvoice-safety-purchase-info {
    margin-bottom: 0.75rem;
}

.CheckoutInvoice-price {
    display: flex;
    justify-content: space-between;
    font-size: 0.875rem;
    color: #666;
    font-weight: 350;
    line-height: 1.125rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.CheckoutInvoice-price.total {
    font-size: 1.125rem;
    line-height: 1.375rem;
    color: black;
}

.CheckoutInvoice-fee {
    display: flex;
    align-items: center;
    gap: 2px;
}
.CheckoutInvoice-fee a {
    display: flex;
}
