.Collection-content-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem 1.5rem 3rem 1.5rem;
}

.Collection-title {
    margin-bottom: 1rem;
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 3.125rem;
    letter-spacing: -0.025rem;
    text-align: center;
}

.Collection-subtitle {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.125rem;
    text-align: center;
    letter-spacing: -0.025rem;
}

.Collection-date {
    font-size: 0.75rem;
    font-weight: 350;
    line-height: 1rem;
    text-align: center;
    color: #999;
}

.Collection-subheader {
    margin-bottom: 1rem;
    font-size: 1.25rem;
    line-height: 1.75rem;
    text-align: center;
    letter-spacing: -0.025rem;
    color: #666666;
}

.Collection-description {
    padding: 0 1.5rem;
    font-size: 1rem;
    font-weight: 350;
    line-height: 1.75rem;
    letter-spacing: -0.025rem;
    margin-bottom: 3rem;
}

.Collection-extra-info-image {
    margin-bottom: 3rem;
}

.Collection-extra-info-list {
    margin-bottom: 1.5rem;
}

.Collection-extra-info-list-title {
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.5rem;
    letter-spacing: -0.0375rem;
    margin-bottom: 0.25rem;
}

.Collection-extra-info-list-subtitle {
    font-size: 0.8125rem;
    font-weight: 350;
    line-height: 1rem;
    letter-spacing: -0.0203125rem;
    color: #828282;
    margin-bottom: 0.75rem;
}
