.SellerPreview {
    display: flex;
    justify-content: space-between;
}

.SellerPreview-link {
    display: flex;
    min-width: 0;
    color: currentcolor;
    text-decoration: none;
    transition: filter 0.25s;
}
.SellerPreview-link:hover,
.SellerPreview-link:focus {
    filter: brightness(85%);
    text-decoration: none;
}

.SellerPreview-profile {
    font-size: 4rem;
    margin-right: 1rem;
    line-height: 1;
}

.SellerPreview-description {
    min-width: 0;
    align-self: center;
}

.SellerPreview-nickname {
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem;
}

.SellerPreview-follower {
    font-size: 0.875rem;
}

.SellerPreview-follow {
    margin-left: 1rem;
    align-self: center;
    font-weight: 350;
}
