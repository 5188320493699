/*
 * Bootstrap variables overrides.
 * Cf ~bootstrap/scss/scss/_variables.scss and https://getbootstrap.com/docs/4.4/getting-started/theming/
 * for more details about variables and options that can be configured.
 */

$gray-100: #bebebe;
$gray-200: #e9ecef;
$gray-400: #c9c9c9;
$gray-500: #b2b2b2;
$gray-600: #6c757d;
$gray-800: #3f3f3f;
$gray-900: #212529;

$blue: #0058ff;
$light: #ffffff;
$dark: #000000;

// Color system

// Options

$enable-responsive-font-sizes: true;

// Spacing

// Body

$body-color: $dark;

// Links

$link-color: $gray-600;
$link-hover-color: $body-color;

// Paragraphs

// Grid breakpoints

// Grid containers

// Grid columns

// Components

$component-active-color: $body-color;
$component-active-bg: $gray-200;

// Typography

$font-family-base: 'FruitsFamily-Font';

// Tables

// Buttons + Forms

// Buttons

$btn-focus-width: 0;

// Forms

// Form validation

// Z-index master list

// Navs

// Navbar

// Dropdowns

// Pagination

// Jumbotron

// Cards

// Tooltips

// Popovers

// Toasts

// Badges

// Modals

$modal-backdrop-opacity: 0.8;

$modal-xl: 1140px;
$modal-lg: 960px;
$modal-md: 720px;
$modal-sm: 540px;

// Alerts

// Progress bars

// List group

// Image thumbnails

// Figures

// Breadcrumbs

// Carousel

// Spinners

// Close

// Code

// Utilities

// Printing
