.CheckoutShipping {
    display: flex;
    flex-direction: column;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.CheckoutShipping-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.CheckoutShipping-header title {
    font-size: 1.25rem;
}

.CheckoutShipping-change {
    font-size: 0.75rem;
    color: #000;
    text-decoration-line: underline;
    font-weight: 350;
}

.CheckoutShipping-add {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    border-radius: 0.25rem;
    border: 0.03125rem solid #D9D9D9;
    padding: 0.75rem 0.25rem;
    font-size: 0.875rem;
    margin-top: 1rem;
    gap: 0.46875rem;
    cursor: pointer;
}

.CheckoutShipping-add text {
    line-height: 18px;
}

.CheckoutShipping-info-container {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    margin-top: 1rem;
    font-size: 0.875rem;
    font-weight: 350;
}

.CheckoutShipping-info-container .column {
    color: #999999;
}

