.SafetyPurchaseInfo {
    cursor: pointer;
    text-decoration: none;
}

.SafetyPurchaseInfo:hover {
    text-decoration: none;
}

.SafetyPurchaseInfo-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    border-radius: 0.25rem;
    background: rgba(0, 41, 255, 0.80);
    font-size: 0.75rem;
    color: #FFFFFF;
    gap: 0.5rem;
}

.SafetyPurchaseInfo.text {
    text-decoration: none;
}


