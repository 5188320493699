.form-select-container {
    display: flex;
    flex-direction: column;
    text-align: start;
}

.form-select-container label {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    letter-spacing: -0.019rem;
}

.form-select-box {
    height: 2.75rem;
    border-radius: 0.5rem !important;
    background-color: #FFFFFF;
    border: .03125rem solid #D9D9D9;
    margin-top: 0.25rem;
    font-size: 0.875rem;
    font-weight: 350;
    line-height: 1.25rem;
    letter-spacing: -0.025rem;
    padding: 0.8125rem 0.75rem;

}

.form-select-box select {
    width: 100%;
    border: none;
    appearance: none;
    background-image: url('icon_dropdown.svg'); /* 가짜 화살표 이미지 설정 */
    background-repeat: no-repeat;
    background-position: right center; /* 이미지 위치 설정 */
    color: #999;
}

.form-select-box .selected {
    color: #000;
}

.form-select-box select:focus {
    outline: none;
}
