.Order-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.Order-title-container img {
    width: 1.5rem;
    height: 1.5rem
}
.Order-title {
    padding-top: 3.563rem;
    padding-bottom: 1rem;
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-weight: 700;
    letter-spacing: -0.045rem;
}
@media (max-width: 768px) {
    .Order {
        max-width: 100vw;
    }
    .Order-title {
        padding: 0.831rem 0;
        text-align: center;
        font-size: 0.875rem;
        font-weight: 700;
        line-height: 1.125rem;
        letter-spacing: -0.025rem;
    }
    .Order-help-button {
        width: 100% !important;
    }
}
.Order-ProductInfo {
    line-height: normal;
    padding: 1rem 0;
    border-bottom: 0.06rem solid #D9D9D9;
}
.Order-Product-image {
    width: 4.25rem;
    height: 5.375rem;
}
.Order-Product-detail {
    margin-left: 0.5rem;
}
.Order-Product-price {
    font-size: 0.875rem;
    font-weight: 700;
}
.Order-Product-title {
    margin-top: 0.563rem;
    font-size: 0.75rem;
    font-weight: 350;
}
.Order-Product-brand {
    margin-top: 0.625rem;
    color: #585858;
    font-size: 0.688rem;
    font-weight: 700;
}

.Order-PurchaseInfo {
    padding-top: 1.5rem;
    padding-bottom: 2rem;
    line-height: normal;
    border-bottom: 0.06rem solid #D9D9D9;
}
.Order-PurchaseInfo-status {
    margin-bottom: 1rem;
}
.Order-PurchaseInfo h2 {
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 1rem;
}
.Order-Check-image {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.5rem;
}
.Order-date {
    color: #198600;
    font-size: 0.75rem;
    font-weight: 700;
}
.red {
    color: red;
}
.Order-PurchaseInfo-fee {
    display: flex;
    align-items: center;
}
.Order-PurchaseInfo-fee a {
    display: flex;
}
.Order-PurchaseInfo-price-type {
    color: #585858;
    font-size: 0.875rem;
    font-weight: 350;
}
.Order-PurchaseInfo-price {
    font-size: 0.75rem;
    font-weight: 350;
}
.Order-PurchaseInfo-total {
    margin-top: 1.125rem;
}
.Order-PurchaseInfo-total-price-type {
    font-size: 0.875rem;
    font-weight: 700;
}
.Order-PurchaseInfo-total-price {
    font-size: 1.125rem;
    font-weight: 700;
}

.Order-ShippingInfo {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    line-height: normal;
    border-bottom: 0.06rem solid #D9D9D9;
}
.Order-ShippingInfo h2 {
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 1rem;
}
.Order-ShippingInfo-address-info {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.Order-ShippingInfo-address {
    font-size: 0.75rem;
    font-weight: 700;
}
.Order-ShippingInfo-address-detail {
    font-size: 0.75rem;
    font-weight: 350;
}
.Order-ShippingInfo-company {
    gap: 4.313rem;
    margin-bottom: 1rem;
}
.Order-ShippingInfo-button-container {
    gap: 0.5rem;
}
.Order-ShippingInfo-button {
    background-color: transparent;
    display: flex;
    padding: 0.5rem 1.063rem;
    justify-content: center;
    align-items: center;
    gap: 0.083rem;
    border-radius: 0.25rem;
    border: 0.125rem solid #000;
}
.Order-ShippingInfo-button a {
    color: black;
}
.Order-ShippingInfo-button a:hover {
    text-decoration: none;
}

.Order-SellerInfo {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    line-height: normal;
}
.Order-SellerInfo h2 {
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
}
.Order-SellerInfo-image {
    width: 4rem;
    height: 4rem;
    font-size: 4rem;
    line-height: 1;
    margin-bottom: 0.5rem;
}
.Order-SellerInfo-nickname {
    font-size: 0.875rem;
    font-weight: 700;
}
.Order-SellerInfo-username {
    color: #808080;
    font-size: 0.688rem;
    font-weight: 350;
}
.Order-SellerInfo-status {
    margin-top: 1.5rem;
    gap: 2.188rem;
}
.Order-SellerInfo-status-item {
    width: 6.188rem;
}
.Order-SellerInfo-status-value {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0.563rem;
}
.Order-SellerInfo-status-type {
    color: #9E9E9E;
    font-size: 0.625rem;
    font-weight: 500;
    letter-spacing: 0.063rem;
}

.Order-help-button {
    margin-top: 2.25rem;
    display: flex;
    width: 21.875rem;
    height: 3rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 0.25rem;
    border: 0.125rem solid #000;
    background: #FFF;
}
.Order-help-button a {
    color: black;
}
