.SearchBar {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: .5rem;
  padding-left: .625rem;
  padding-right: .625rem;
  gap: .5rem;
  height: 2.5rem;
  flex: 1;
  margin-right: .625rem;
  border: .03125rem solid #D9D9D9;
  cursor: pointer;
  overflow: hidden;
}

@media (max-width: 767px) {
  .SearchBar {
    display: none;
  }
}

.SearchBar-text {
    color: #999;
    font-size: 1rem;
    font-style: normal;
    font-weight: 350;
    line-height: 1.25rem;
    letter-spacing: -.025rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.SearchBar-mobile {
    flex: 1;
    overflow: hidden;
}

.SearchBar-mobile-icon {
    display: flex;
    justify-content: flex-end;
    margin-right: 0.25rem;
}

.SearchBar-mobile-input {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: .5rem;
    height: 2.5rem;
    gap: .5rem;
    border: .03125rem solid #D9D9D9;
    padding-left: .625rem;
    padding-right: .625rem;
    margin-right: 1.25rem;
    overflow: hidden;
}


@media (min-width: 768px) {
    .SearchBar-mobile {
        display: none;
    }
}
