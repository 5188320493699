.NotFound-title {
    margin-top: 6rem;
    margin-bottom: 2rem;
    font-weight: bold;
    text-align: center;
}

.NotFound-description {
    text-align: center;
}
