.FacebookLoginButton {
    width: 100%;
    height: 3.125rem;
    background-color: #1B76F1;
    border: 0;
    border-radius: 0.25rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
}
