.UserPreview {
    display: inline-block;
    width: 1em; /* adaptative size */
    height: 1em; /* adaptative size */
    line-height: 1em; /* adaptative size */
}

.UserPreview-image {
    display: inline-block;
    width: 100%;
    height: 100%;
    vertical-align: top;
    object-fit: cover;
}
.UserPreview-image--missing {
    color: var(--white);
    background-color: #d9d9d9;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    text-transform: uppercase;
}
