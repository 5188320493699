.Download {
    display: flex;
    min-height: calc(100vh - 3.5rem); /* screen height - navbar height */
    flex-direction: column;
    justify-content: center;
}

.Download-content {
    text-align: center;
}
@media (min-width: 576px) {
    .Download-content {
        display: grid;
        text-align: left;
    }
}

.Download-title {
    margin-bottom: 1.5rem;
    font-size: 2rem;
    font-weight: bold;
    word-break: keep-all;
}
@media (min-width: 576px) {
    .Download-title {
        grid-column: 1;
        grid-row: 1;
        margin-bottom: 0;
    }
}
@media (min-width: 768px) {
    .Download-title {
        font-size: 3rem;
    }
}
@media (min-width: 992px) {
    .Download-title {
        font-size: 3.5rem;
    }
}

.Download-nobreak {
    white-pace: no-break;
}

.Download-group {
    margin-bottom: 5rem;
}
@media (min-width: 576px) {
    .Download-group {
        grid-column: 1;
        grid-row: 2;
        margin-bottom: 0;
        align-self: end;
    }
}

.Download-link {
    margin-top: 1rem;
    margin-right: 0.75rem;
}
.Download-link:last-of-type {
    margin-right: 0;
}
.Download-link:hover,
.Download-link:active {
    opacity: 0.85;
}

.Download-appstore {
    height: 3.5rem;
    width: auto;
}

.Download-qrcode {
    width: 10rem;
    height: 10rem;
}
@media (min-width: 576px) {
    .Download-qrcode {
        grid-column: 2;
        grid-row: 1 / span 2;
        justify-self: end;
    }
}
