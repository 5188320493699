.SearchUserList-title {
  padding: 1rem;
  font-size: 1.125rem;
  font-weight: bold;
  border-top: 0.0206rem solid #D9D9D9;
  line-height: 1.375rem;
  letter-spacing: -0.025rem;
}

.SearchUserList-card {
  padding: 0.75rem 1rem;
}

.SearchUserList-card:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.08) 100%), #FFF;
}

.SearchUserList-card-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 2.75rem;

  .profile {
    display: flex;
    flex-direction: row;
    gap: .625rem;
    align-items: center;
  }

  .image {
    font-size: 3.15rem;
  }
}

.SearchBrandList-card-name {
  display: flex;
  flex-direction: column;
  gap: .125rem;

  .name {
    font-size: .875rem;
    line-height: 1.125rem;
    letter-spacing: -0.025rem;
    word-break: break-word;
  }

  .sub-name {
    font-size: .75rem;
    font-weight: 350;
    color: #B1B1B1;
    line-height: 1rem;
    letter-spacing: -0.025rem;
    word-break: break-word;
  }
}
