.HorizontalProductsList {
    position: relative;
    margin-bottom: 0;
    overflow: hidden;
    flex-wrap: nowrap;
}

.HorizontalProductsList--multiple {
    flex-wrap: wrap;
    row-gap: 1.5rem;
}
@media (max-width: 991.98px) {
    /* scrollable list only mobile and vertical table */
    .HorizontalProductsList--mobile {
        overflow-x: auto;
    }
    .HorizontalProductsList--mobile::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera */
    }
    /* width is slightly smaller than the equivalent row-cols-sm-* so the user can see the list is scrollable */
   .HorizontalProductsList--mobile.row-cols-sm-3 .HorizontalProductsList-item {
       flex: 0 0 28%;
       max-width: 28%;
   }
   .HorizontalProductsList--mobile.row-cols-sm-4 .HorizontalProductsList-item {
       flex: 0 0 22%;
       max-width: 22%;
   }
}
@media (min-width: 576px) and (max-width: 991.98px) {
    .HorizontalProductsList--mobile {
        margin-right: 0; /* force overflow clipping alignment with main content on tablet */
    }
}
@media (max-width: 575.98px) {
    /* width is slightly smaller than the equivalent row-cols-* so the user can see the list is scrollable */
   .HorizontalProductsList--mobile.row-cols-2 .HorizontalProductsList-item {
       flex: 0 0 44%;
       max-width: 44%;
       padding-right: 0;
   }
   .HorizontalProductsList--mobile.row-cols-3 .HorizontalProductsList-item {
       flex: 0 0 29%;
       max-width: 29%;
       padding-right: 0;
   }
}

.HorizontalProductsList-item--loading {
    /* render invisible placeholder data while loading, to set the container height and guarantee a stable layout */
    visibility: hidden;
}

.HorizontalProductsList-loading {
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    max-width: unset;
}
