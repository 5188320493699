/**
 * Custom font made from NeueHassGrotesk font for the latin characters and
 * Nanum Barun Gothic font from https://hangeul.naver.com/2017/nanum
 *
 * Due to its size even after WOFF2 compression (1.5MB per variant) the font is not directly suitable as a webfont.
 * It has thus been optimized and restricted to the subset of the character set with the most frequent characters.
 * The following links explain font subsetting for Korean:
 * - https://spoqa.github.io/2015/10/14/making-spoqa-han-sans.html
 * - https://www.44bits.io/ko/post/optimization_webfont_with_pyftsubnet
 *
 * The subset font has been generated with Transfonter (https://transfonter.org) from the custom font variants,
 * by including only glyphs from the latin subsets and the most frequent characters in Korean. The font hinting and
 * vertical metrics were also fixed due to rendering and alignment issues on Windows and Ubuntu.
 * Transfonter Options:
 * - Fix vertical metrics: On
 * - Formats: WOFF2
 * - Hinting: TTFAutoHint
 * - Subsets: Latin, Latin Extended
 * - Characters: list copied from https://raw.githubusercontent.com/nacyot/korean_subset_glyphs/master/glyphs.txt
 */
@font-face {
    font-family: 'FruitsFamily-Font';
    font-style: normal;
    font-weight: 350;
    font-display: swap;
    src: url('../font/FruitsFamily_Light.woff2') format('woff2');
}

@font-face {
    font-family: 'FruitsFamily-Font';
    font-style: normal;
    font-display: swap;
    src: url('../font/FruitsFamily_Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'FruitsFamily-Font';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../font/FruitsFamily_Bold.woff2') format('woff2');
}

/* TODO refactor deprecated font classnames and replace them with equivalent font weight styles */
.font-nhaasgrotesk {
    font-weight: bold;
}

.font-proxima {
    font-weight: 800;
}
