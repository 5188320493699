.accordion {
    width: 100%;
    margin: 0 auto;
    border: 0.0625rem solid #ddd;
    border-radius: 0.25rem;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}

.accordion-item {
    border-bottom: 0.0625rem solid #ddd;
}

.accordion-item:last-child {
    border-bottom: none;
}

.accordion-title {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    cursor: pointer;
    transition: background 0.2s ease;
    border-top: 0.03125rem solid #e6e6e6;
}

.accordion-img {
    width: 1.25rem;
    height: 1.25rem;
}

.accordion-content {
    padding: 0 1rem 1rem 1rem;
    background: #fff;
}

.accordion-content-hidden {
    display: none;
}
