.SearchModalDialog {
  min-width: 100%;
  min-height: 100%;
  margin: 0;
  background-color: white;
}

.SearchModalDialog-body {
  height: 100vh;
  overflow-y: auto !important;
  padding: 0;
}

.SearchModalDialog-content {
  display: flex;
  flex-direction: column;
  max-width: 767px;
  margin: auto;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .5rem;
    background-color: white;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    position: sticky;
    top: 0;

    .search {
      flex: 1;
    }

    .closeContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.25rem;
      height: 2.25rem;
      cursor: pointer;

      img {
        width: .875rem;
        height: .875rem;
      }
    }
  }
}

.SearchModalDialog-footer {
  height: 5rem
}
