.SimilarProductList-container {
    padding-top: 0.5rem;
    padding-bottom: 2rem;
}

.SimilarProductList-title {
    font-size: 1.25rem;
    line-height: 1.5rem;
}

.SimilarProductList-item-container {
    margin-top: 0.5rem;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 4px;
    width: 100%;
}

.SimilarProductList-item {
    cursor: pointer;
}

.SimilarProductList-item-image {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 1;
}

.SimilarProductList-moreButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF; /* White background */
    cursor: pointer;
    border: 0.03125rem solid #0000001A;
    color: #000000;
}

.SimilarProductList-moreButton:hover {
    text-decoration: none;
    color: #6C757D;
}
