.StatusErrorProduct-container {
    min-height: 18.75rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.StatusErrorProduct-title {
    margin-top: 1.5rem;
    font-size: 1rem;
    line-height: 1.25rem;
    letter-spacing: -0.025rem;
}

.StatusErrorProduct-section-title {
    font-weight: bold;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    line-height: 1.75rem;
}

@media (min-width: 768px) {
    .StatusErrorProduct-title {
        margin-top: 2.25rem;
        font-size: 1.5rem;
        line-height: 1.875rem;
        letter-spacing: -0.0375rem;
    }
}
