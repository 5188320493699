.DiscoveryCollectionsList {
    position: relative;
}
@media (max-width: 575.98px) {
    .DiscoveryCollectionsList {
        margin-left: -15px; /* Bootstrap override: $grid-gutter-width / 2 */
        margin-right: -15px; /* Bootstrap override: $grid-gutter-width / 2 */
        overflow: hidden;
    }
}

.DiscoveryCollectionsList-loading {
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    max-width: unset;
}

.DiscoveryCollectionsList-large {
    margin-bottom: 3.5rem;
}
.DiscoveryCollectionsList-large--loading,
.DiscoveryCollectionsList-list--loading {
    /* render invisible placeholder data while loading, to set the container height and guarantee a stable layout */
    visibility: hidden;
}

.DiscoveryCollectionsList-list {
    margin-bottom: 0;
    overflow: hidden;
    flex-wrap: nowrap;
}
@media (max-width: 991.98px) {
    /* scrollable list only mobile and vertical table */
    .DiscoveryCollectionsList-list--mobile {
        overflow-x: auto;
    }
    /* width is slightly smaller than the equivalent row-cols-sm-* so the user can see the list is scrollable */
   .DiscoveryCollectionsList-list--mobile .DiscoveryCollectionsList-item {
       flex: 0 0 65%;
       max-width: 65%;
   }
}
@media (min-width: 576px) and (max-width: 991.98px) {
    .DiscoveryCollectionsList-list--mobile {
        margin-right: 0; /* force overflow clipping alignment with main content on tablet */
    }
}
@media (max-width: 575.98px) {
    .DiscoveryCollectionsList-list--mobile .DiscoveryCollectionsList-item {
        padding-right: 0;
    }
}

.DiscoveryCollectionsList-49by40 {
    position: relative;
    margin-bottom: 0.75rem;
    max-height: 60vh;
    overflow: hidden;
}
.DiscoveryCollectionsList-49by40::before {
    content: '';
    display: block;
    padding-top: 81.63%;
}

.DiscoveryCollectionsList-49by40-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.DiscoveryCollectionsList-link {
    transition: filter 0.25s;
}
.DiscoveryCollectionsList-link:hover,
.DiscoveryCollectionsList-link:focus {
    filter: brightness(75%);
}

.DiscoveryCollectionsList-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.DiscoveryCollectionsList-title {
    margin-bottom: 0.3125rem;
    font-size: 1.125rem;
    font-weight: bold;
    line-height: 1.4375rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.DiscoveryCollectionsList-title--large {
    margin-bottom: 0.1875rem;
    font-size: 1.5rem;
    line-height: 1.9375rem;
}
@media (max-width: 575.98px) {
    .DiscoveryCollectionsList-title--first {
        margin-left: 15px; /* Bootstrap override: $grid-gutter-width / 2 */
    }
}

.DiscoveryCollectionsList-subtitle {
    font-size: 0.875rem;
    line-height: 1rem;
    color: #797979;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.DiscoveryCollectionsList-subtitle--large {
    font-size: 1rem;
    line-height: 1.3125rem
}
@media (max-width: 575.98px) {
    .DiscoveryCollectionsList-subtitle--first {
        margin-left: 15px; /* Bootstrap override: $grid-gutter-width / 2 */
    }
}
