.SearchBrandList-title {
    padding: 1rem;
    font-size: 1.125rem;
    font-weight: bold;
    line-height: 1.375rem;
    letter-spacing: -0.025rem;
}

.SearchBrandList-card {
    padding: 0.75rem 1rem;
}

.SearchBrandList-card:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.08) 100%), #FFF;
}

.SearchBrandList-card-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.SearchBrandList-card-name {
    display: flex;
    flex-direction: column;
    gap: .125rem;

    .name {
        font-size: .875rem;
        line-height: 1.125rem;
        letter-spacing: -.025 rem;
        word-break: break-word;

        .bold {
            font-weight: 750;
        }
    }

    .sub-name {
        font-size: .75rem;
        font-weight: 350;
        color: #B1B1B1;
        line-height: 1rem;
        letter-spacing: -.025rem;
        word-break: break-word;
    }
}

