.index-noscript {
    position: fixed;
    display: grid;
    place-items: center;
    top: 0;
    left: 50%;
    height: 3.5rem;
    transform: translateX(-50%);
    text-align: center;
    color: var(--gray);
    z-index: 1060;
}
@media (min-width: 768px) {
    .index-noscript {
        height: auto;
    }
}
