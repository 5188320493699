.SearchInputContainer {
  display: flex;
  flex-direction: row;
  gap : 0.25rem;
  border-radius: 0.5rem !important;
  padding: 0.5rem 0.5rem 0.5rem .375rem;
  background-color: #F4F4F4;
  border: .03125rem solid #D9D9D9;

  &.focused {
    box-shadow: 0 0 0 .125rem rgba(24,119,242,0.75);
  }

  .image {
    width: 1.5rem;
    height: 1.5rem;
  }
}


.SearchInputContainer-input-container {
  flex: 1;
}

.SearchInputContainer-search-input {
  width: 100%;
  background-color: #F4F4F4;
  border: none;
  outline: none;
  padding: 0;
  font-size: .875rem;
  font-weight: 350;
  line-height: 1.125rem ;
  letter-spacing: -.025rem;

  &::placeholder {
    color: #999;
  }

  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
    width: 1.375rem;
    height: 1.375rem;
    background-image: url('../assets/icon_input_remove.svg');
    background-position: center center;
    background-repeat: no-repeat;
    cursor:pointer;
  }
}

SearchInputContainer-search-input:focus {
  outline:none;
}
