.FollowButton {
    width: 80px;
    height: 30px;
    display: flex;
    background-color: #000000;
    border-radius: 3px;
    justify-content: center;
    align-items: center;
}

.FollowButton-text {
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    color: white;
}
